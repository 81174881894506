import "./styles.css";
import { memo } from "react";
import { CSVData } from "../../types/index";
type RewardProps = {
  data: CSVData[] | undefined;
};
export function handleNumberDisplay(data: number) {
  return Math.trunc(data).toString();
}
const RewardSection = (props: RewardProps) => {
  const { data } = props;

  return (
    <div id="reward_data_section">
      <div className="data_card">
        <div>
          <p style={{ margin: "5px" }}>Reward Statistics</p>
          <p className="cell_header  color_bone">
            On the 14th of November we took a snapshot of your staking rewards.
          </p>
        </div>
        <div
          style={{
            width: "fit-content",
            margin: "10px auto auto auto",
          }}
        >
          {data && data[0].staked && data[0].staked_aa && data[0].staked_am && (
            <div className="reward-item">
              <p className="label color_bone">CWEB you staked:</p>
              <p className="value color_bone">
                {data[0].unstake_date_s
                  ? handleNumberDisplay(
                      Number(data[0].staked_aa) + Number(data[0].staked_am)
                    )
                  : handleNumberDisplay(
                      Number(data[0].staked) +
                        Number(data[0].staked_aa) +
                        Number(data[0].staked_am)
                    )}
              </p>
            </div>
          )}
          {data && data[0].reward && (
            <div className="reward-item">
              <p className="label color_bone">Staking rewards:</p>
              <p className="value color_bone">
                {handleNumberDisplay(
                  data[0].unstake_date_s
                    ? Number(data[0].reward_aa) + Number(data[0].reward_am)
                    : Number(data[0].reward) +
                        Number(data[0].reward_aa) +
                        Number(data[0].reward_am)
                )}
              </p>
            </div>
          )}
          {data && data[0].ost_rewards && (
            <div className="reward-item">
              <p className="label color_bone">OST rewards:</p>
              <p className="value color_bone">
                {handleNumberDisplay(data[0].ost_rewards)}
              </p>
            </div>
          )}
          {data && data[0].total_collateral && (
            <div className="reward-item">
              <p className="label color_bone">Collateral provided:</p>
              <p className="value color_bone">
                {handleNumberDisplay(data[0].total_collateral)}
              </p>
            </div>
          )}
          {data && data[0].staked && (
            <div className="reward-item" style={{ marginTop: "10px" }}>
              <p className="label">You have received a total of: </p>
              <p className="value" style={{ marginLeft: "5px" }}>
                {handleNumberDisplay(data[0].total_to_pay)} CWEB
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(RewardSection);
