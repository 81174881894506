// The purpose of this config system is to enable runtime environment variables
// in a production build of react.
//
// We do this by running envsub (like envsubst, the Unix command) on index.html
// to substitute environment variables into the main html file.
//
// We then pick those variables up here.

const RE = /\$\{\s*\w+\s*\}/;

const isNotInterpolated = (kv: (string | null)[]): kv is [string, string] =>
  !!kv[0] && !!kv[1] && !RE.test(kv[1]);

export const read = (): Record<string, string> =>
  Array.from(document.querySelectorAll('[itemid="config"] meta[itemprop]'))
    // map to k,v array
    .map((el) => [el.getAttribute("itemprop"), el.getAttribute("content")])
    // remove non-interpolated entries
    .filter(isNotInterpolated)
    // convert to object
    .reduce(
      (m, [k, v]) => ({
        ...m,
        [k]: v,
      }),
      {}
    );

export const {
  REACT_APP_ACTIVE_BOOST_WINDOW = process.env.REACT_APP_ACTIVE_BOOST_WINDOW,
  REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT,
  REACT_APP_AVERAGE_APR_OFF = process.env.REACT_APP_AVERAGE_APR_OFF,
  REACT_APP_COLLATE_OFF = process.env.REACT_APP_COLLATE_OFF,
  REACT_APP_CURRENT_BOOST_OFF = process.env.REACT_APP_CURRENT_BOOST_OFF,
  REACT_APP_INCREASED_OFF = process.env.REACT_APP_INCREASED_OFF,
  REACT_APP_NEXT_BOOSTER_WINDOW = process.env.REACT_APP_NEXT_BOOSTER_WINDOW,
  REACT_APP_REWARD_RATE_OFF = process.env.REACT_APP_REWARD_RATE_OFF,
  REACT_APP_RPC_URL_MAINNET = process.env.REACT_APP_RPC_URL_MAINNET,
  REACT_APP_TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS,
  REACT_APP_TOTAL_COLLATE_OFF = process.env.REACT_APP_TOTAL_COLLATE_OFF,
  REACT_APP_TOTAL_REWARD_OFF = process.env.REACT_APP_TOTAL_REWARD_OFF,
  REACT_APP_TOTAL_STAKE_AMOUNT_OFF = process.env
    .REACT_APP_TOTAL_STAKE_AMOUNT_OFF,
  REACT_APP_TOTAL_STAKE_OFF = process.env.REACT_APP_TOTAL_STAKE_OFF,
  REACT_APP_TOTAL_STAKE_STATIS_OFF = process.env
    .REACT_APP_TOTAL_STAKE_STATIS_OFF,
  REACT_APP_WALLET_STAKE_OFF = process.env.REACT_APP_WALLET_STAKE_OFF,
  REACT_APP_RESTRUCTURE_TEXT = process.env.REACT_APP_RESTRUCTURE_TEXT,
  REACT_APP_SUBSCRIPTION_INPUT_ENDPOINT = process.env
    .REACT_APP_SUBSCRIPTION_INPUT_ENDPOINT,
  REACT_APP_LIVENESS_ENDPOINT = process.env.REACT_APP_LIVENESS_ENDPOINT,
  REACT_APP_EPOCH_TIME = process.env.REACT_APP_EPOCH_TIME,
  REACT_APP_STAKING_PAYOUT = process.env.REACT_APP_STAKING_PAYOUT,
  REACT_APP_REWARD_ENDPOINT = process.env.REACT_APP_REWARD_ENDPOINT,
} = read();
