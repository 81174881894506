import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import styles from "./index.module.css";
type Props = {
  open: boolean;
  handleClose: () => void;
};

const StakingWarningModal = (props: Props) => {
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={props.open}
      onClose={props.handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={props.open}>
        <Box id={styles.rewardModalContainer}>
          <div className={styles.validity}>
            <div className={styles.validityBlock}>
              <p id="error_message" style={{ margin: "0px" }}>
                IMPORTANT: Dear Staker, you must keep your CWEB rewards in your
                EVM wallet and not move them in order to maintain the additional
                privileges and compounding rewards!
              </p>
              <p>
                We are happy to announce that the Coinweb staking program is
                entering a new phase:
              </p>
              <p>
                With the imminent launch of CWAP, the first truly
                blockchain-agnostic dApp, blockchain technology has taken a
                quantum leap towards its inevitable point of convergence of
                protocol and application-level unification. By enabling
                unprecedented DeFi composability to native Bitcoin and across
                the different isolated blockchain platforms, the building blocks
                are in place for the emergence of a new unified DeFi and dApp
                utility ecosystem, resulting in a disruptive paradigm shift.
                This move towards unification is made possible by enabling
                specific common primitives to be linked against individual
                incentive mechanisms of each underlying blockchain and dApp
                platform. Currently, CWEB, with its blockchain-agnostic
                properties, is the only blockchain primitive that can link and
                unify the different L1-specific incentive mechanisms. In short,
                CWEB is needed to link the different blockchains and dApps
                together and drive additional abstractions and value capture
                above them, and the more “native CWEB fuel” that will be
                available, the faster the unified DeFi and dApp ecosystem can
                grow.
              </p>
              <p>
                As with most technological paradigm shifts, it is the early
                adopters that benefit the most, this is also what we want to
                facilitate for the Coinweb community, and combined with the
                benefits of sufficient native “CWEB fuel” for the Coinweb
                application layer, we have decided to not only extend the
                current staking program but also add additional rewards and
                privileged access for loyal stakers. Compounded staking rewards
                will be accumulated on the distributed native CWEB rewards, with
                the requirement that tokens are not moved from the staking
                wallet. In addition to this, funds from staking rewards will
                have privileged access to participate in key DeFi ecosystem
                components as well as airdrops and other benefits. OST token
                holders will benefit from boosted versions of the above.
              </p>
              <p>
                Announcement and eligibility of DeFi participation will be
                available on the CWEB staking page.
              </p>
              <p>
                First privileged project “ImPACT Synthetics” - High yield
                Mirrored asset synthetic protocol, adding yield to native assets
                including Bitcoin
              </p>
              <p>Coinweb Team</p>

              <div className={styles.greenBlock} onClick={props.handleClose}>
                <div className={styles.closeText}>
                  <p>Close</p>
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
};
export default StakingWarningModal;
