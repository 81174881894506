/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useContext, useEffect, useState } from "react";
import { InjectedConnector } from "@web3-react/injected-connector";
import { useWeb3React } from "@web3-react/core";
import config from "../config";
import { WalletType } from "../types";
import { useLocalStorageState } from "../hooks";
import { useContracts } from "./contracts";

export interface IWalletContext {
  connected: boolean;
  account: Maybe<string>;
  balance: number;
  tokenBalance: number;
  chainId: Maybe<number>;
  connect: (type: WalletType) => Promise<void>;
  disconnect: () => Promise<void>;
  updateBalance: () => Promise<void>;
  updateTokenBalance: () => Promise<void>;
  getTokenBalance: (
    userAddress: string,
    tokenAddress: string
  ) => Promise<number>;
  burn: (
    amount: number,
    setStakeWarning: React.Dispatch<React.SetStateAction<boolean>>,
    setPending: React.Dispatch<React.SetStateAction<boolean>>,
    setDisabledStaking: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
  signMessage: (
    message: string,
    setMessage: React.Dispatch<React.SetStateAction<string | void>>,
    setStakeWarning: React.Dispatch<React.SetStateAction<boolean>>,
    setPending: React.Dispatch<React.SetStateAction<boolean>>,
    setDisabledStaking: React.Dispatch<React.SetStateAction<boolean>>
  ) => Promise<void>;
}

export const metamaskInjected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42, 56, 97],
});

export const WalletContext = React.createContext<Maybe<IWalletContext>>(null);

export const WalletProvider = ({ children = null as any }) => {
  const { web3 } = useContracts();
  const { activate, deactivate, active, chainId, account } = useWeb3React();
  const [walletType, setWalletType] = useLocalStorageState("wallet_type", "");
  const [connected, setConnected] = useState<boolean>(false);
  const [balance, setBalance] = useState<number>(0);
  const [tokenBalance, setTokenBalance] = useState<number>(0);

  const connect = useCallback(
    async (type: WalletType = WalletType.MetaMask) => {
      try {
        if (type === WalletType.MetaMask) {
          await activate(metamaskInjected);
        }
        setWalletType(type.toString());
      } catch (err) {
        console.error("Wallet connect failed!");
      }
    },
    [activate, setWalletType]
  );

  const disconnect = async () => {
    await deactivate();
    setWalletType(null);
  };

  useEffect(() => {
    if (walletType) {
      if (walletType === "metamask") {
        connect(WalletType.MetaMask);
      } else if (walletType === "walletconnect") {
        connect(WalletType.WalletConnect);
      }
    }
  }, [connect, activate, walletType]);

  useEffect(() => {
    if (active) {
      if (chainId) {
        if (chainId === config.networkId) {
          setConnected(true);
        } else {
          deactivate();
          console.error(`Please connect ${config.networkName}!`);
        }
      }
    } else {
      setConnected(false);
    }
  }, [active, chainId, deactivate]);
  // const sendTransactionWithData = async (account: string) => {
  //   if (account) {
  //     try {
  //       await web3.eth.sendTransaction({
  //         from: account,
  //         to: account, // Sending to the same account or a different one, if needed
  //         value: "0", // Sending 0 ETH
  //         data: {metamaskAddress: account}, // Adding custom data
  //       });
  //       console.log("Transaction sent successfully");
  //     } catch (error) {
  //       console.error("Transaction failed:", error);
  //     }
  //   } else {
  //     console.error("No account connected");
  //   }
  // };
  const updateBalance = useCallback(async () => {
    if (account) {
      const res = await web3.eth.getBalance(account);
      setBalance(Number(web3.utils.fromWei(res)));
    } else {
      setBalance(0);
    }
  }, [account]);

  const updateTokenBalance = useCallback(async () => {
    if (account) {
      const tokenContract = new web3.eth.Contract(
        config.tokenAbi as any,
        config.tokenAddress
      );

      try {
        const res = await tokenContract.methods
          .balanceOf(account)
          .call({ from: account, gasLimit: 30000 });
        setTokenBalance(Math.floor(Number(web3.utils.fromWei(res))));
      } catch (err: any) {
        console.error(err);
      }
    } else {
      setTokenBalance(0);
    }
  }, [account]);

  useEffect(() => {
    updateBalance();
    updateTokenBalance();
  }, [account]);

  const getTokenBalance = async (userAddress: string, tokenAddress: string) => {
    const tokenContract = new web3.eth.Contract(
      config.tokenAbi as any,
      tokenAddress
    );

    try {
      const res = await tokenContract.methods.balanceOf(userAddress).call();
      return Math.floor(Number(web3.utils.fromWei(res)));
    } catch (err: any) {
      console.error(err);
    }
    return 0;
  };

  const signMessage = async (
    message: string,
    setMessage: React.Dispatch<React.SetStateAction<string | void>>,
    setStakeWarning: React.Dispatch<React.SetStateAction<boolean>>,
    setPending: React.Dispatch<React.SetStateAction<boolean>>,
    setDisabledStaking: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (account) {
      web3.eth.personal.sign(message, account, "", function (error, signature) {
        setPending(true);
        setDisabledStaking(true);
        if (signature) {
          setMessage(signature);
          setStakeWarning(true);
        } else if (error) {
          setMessage("error");
        }
      });
    }
    setPending(false);
    setDisabledStaking(false);
  };

  const sleep = (milliseconds: number) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  const burn = async (
    amount: number,
    setStakeWarning: React.Dispatch<React.SetStateAction<boolean>>,
    setPending: React.Dispatch<React.SetStateAction<boolean>>,
    setDisabledStaking: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    if (account) {
      const tokenContract = new web3.eth.Contract(
        config.tokenAbi as any,
        config.tokenAddress
      );
      const data = web3.utils.sha3("epoch1");
      try {
        await tokenContract.methods
          .burn(web3.utils.toWei(amount.toString(), "ether"), data)
          .send(
            { from: account },
            async function (error: any, transactionHash: any) {
              let transactionReceipt = null;
              setDisabledStaking(true);
              while (transactionReceipt == null) {
                // Waiting expectedBlockTime until the transaction is mined
                transactionReceipt = await web3.eth.getTransactionReceipt(
                  transactionHash
                );
                await sleep(500);
                setStakeWarning(true);
                setPending(true);
              }
              setPending(false);
              setDisabledStaking(false);
            }
          );
      } catch (err: any) {
        console.error(err);
      }
    }
  };

  return (
    <WalletContext.Provider
      value={{
        connected,
        account,
        balance,
        tokenBalance,
        chainId,
        connect,
        disconnect,
        updateBalance,
        updateTokenBalance,
        getTokenBalance,
        burn,
        signMessage,
      }}
    >
      {children}
    </WalletContext.Provider>
  );
};

export const useWallet = () => {
  const context = useContext(WalletContext);

  if (!context) {
    throw new Error("Component rendered outside the provider tree");
  }

  return context;
};
