import { Scalars } from "../../generated/graphql";

export const prepareAddress = (address: string | undefined) => {
  if (address && address.length === 42 && address.startsWith("0x")) {
    return address.substring(2);
  } else {
    return undefined;
  }
};

export type StakingStatisticsSubset = {
  //  __typename?: 'StakingStatistics';
  currentStakedCweb: Scalars["Float"];
  //  forfeitedL2Rewards24H: Scalars['Float'];
  stakedCwebDays: Scalars["Float"];
  totalAccumulatedL2Rewards: Scalars["Float"];
  //  totalClaimedForfeitedL2Rewards: Scalars['Float'];
  totalForfeitedCwebCollateral: Scalars["Float"];
  totalForfeitedL2Rewards: Scalars["Float"];
  totalStakedCweb: Scalars["Float"];
  totalStakedCwebCollateral: Scalars["Float"];
  currentBoostStakeCweb: Scalars["Float"];
  currentBoostForfeitedL2: Scalars["Float"];
  totalBoostRewards: Scalars["Float"];
  amountOfStakers: Scalars["Int"];
  totalUnstakedCweb: Scalars["Float"];
};

export type BoosterStatisticsSubset = {
  endsAt: Scalars["TimeGql"];
  rewardPool: Scalars["Float"];
  startsAt: Scalars["TimeGql"];
};
export enum StakerType {
  Unstaker = "unstaker",
  Newstaker = "newstaker",
  Earlystaker = "earlystaker",
}
export type StakingData = {
  forAddr: StakingStatisticsSubset;
  // The number shown in the "Amount to stake"
  // input box.
  amountToStake: number;
  // The current ERC20 CWEB token balance
  tokenBalance: number;
};

export type BoosterData = {
  booster: BoosterStatisticsSubset;
};

export enum SignalingMethod {
  Burn,
  Sign,
  Nothing,
}

// If staked = X, what was burned?
export const burnedForStaked = (staked: number): number =>
  Number(Math.max(0, (staked - 10000) * 0.1).toFixed(3));

// For amountToStake, is burning required?
// If what needs to be burned is bigger after adding amountToStake, then yes.
export const needBurn = ({
  amountToStake,
  forAddr: { currentStakedCweb },
}: StakingData): boolean =>
  burnedForStaked(amountToStake + currentStakedCweb) >
  burnedForStaked(currentStakedCweb);

// Need to warn that not everything is being staked?
export const needNotStakingFullWarning = ({
  amountToStake,
  tokenBalance,
  forAddr: { currentStakedCweb },
}: StakingData): boolean => amountToStake + currentStakedCweb < tokenBalance;

export const needStakingTooMuchWarning = ({
  amountToStake,
}: StakingData): boolean => amountToStake > 750000;

// What needs to be burned
export const burnAmount = ({
  amountToStake,
  forAddr: { currentStakedCweb },
}: StakingData): number => burnedForStaked(amountToStake + currentStakedCweb);

export const signalingMethod = (data: StakingData): SignalingMethod => {
  if (burnAmount(data) > 0) {
    return SignalingMethod.Burn;
  }
  if (data.amountToStake > 0) {
    return SignalingMethod.Sign;
  }
  return SignalingMethod.Nothing;
};
export const shouldDisplayWarning = (data: StakingData): Boolean => {
  // function should return true if
  // user would stake part of the account when burning
  // and when amountToStake is 10k
  const availableBalance = availableBalanceForStaking(data);
  if (
    (signalingMethod(data) === SignalingMethod.Burn ||
      (signalingMethod(data) === SignalingMethod.Sign &&
        data.amountToStake === 10000)) &&
    data.amountToStake < availableBalance
  ) {
    return true;
  }
  return false;
};
export const shouldDisplayWarningRecent = (data: StakingData): Boolean => {
  if (
    data.forAddr.currentStakedCweb > 0 &&
    data.forAddr.currentStakedCweb < 10000 &&
    availableBalanceForStaking(data) > 0
  ) {
    return true;
  }
  return false;
};
export const shouldDisplayWarningAmount = (data: StakingData): Boolean => {
  // function should return true if user stakes larger than limit at env.
  const limit = process.env.REACT_APP_LARGE_AMOUNT_WARNING_LIMIT;
  if (data.forAddr.currentStakedCweb + data.amountToStake > Number(limit)) {
    return true;
  }
  return false;
};
export const shouldDisplayInputError = (data: StakingData): Boolean => {
  if (
    data.forAddr.currentStakedCweb === 0 && // user has not staked before
    data.tokenBalance > 10000 && // user have more than 10k
    data.amountToStake < 10000
  ) {
    // user wants to stake less than 10k
    return true;
  }
  return false;
};

export const availableBalanceForStaking = ({
  tokenBalance,
  forAddr: { currentStakedCweb },
}: StakingData): number =>
  Number(
    Math.max(
      0,
      tokenBalance - (currentStakedCweb - burnedForStaked(currentStakedCweb))
    ).toFixed(3)
  );

export const neededBurnAmount = (model: StakingData) =>
  Number(
    (
      burnAmount(model) -
      (model.forAddr.totalStakedCwebCollateral -
        model.forAddr.totalForfeitedCwebCollateral)
    ).toFixed(3)
  );

export const calculateL2Collateral = (model: StakingData) =>
  Number(
    (
      model.forAddr.totalStakedCwebCollateral -
      model.forAddr.totalForfeitedCwebCollateral
    ).toFixed(3)
  );

export const calculateRewards = (model: StakingData) =>
  Number(
    (
      model.forAddr.totalAccumulatedL2Rewards -
      model.forAddr.totalForfeitedL2Rewards +
      model.forAddr.totalBoostRewards
    ).toFixed(3)
  );

export const transferToken = (model: StakingData, amount: number) => {
  //Function for unstaking
  //If user transfers the money more than avilable balance, then
  //user would be forfited than currentStakedCweb would be 0
  // forfeited reward and collateral amounts would be same
  if (amount > availableBalanceForStaking(model)) {
    model.tokenBalance = model.tokenBalance - amount;
    model.forAddr.currentStakedCweb = 0;
    model.forAddr.totalForfeitedCwebCollateral = Math.round(
      model.forAddr.totalStakedCwebCollateral
    );
    model.forAddr.totalForfeitedL2Rewards =
      model.forAddr.totalAccumulatedL2Rewards;
  } else {
    model.tokenBalance = model.tokenBalance - amount;
  }
};

export const setMinAmountToStake = (model: StakingData) => {
  //if user has not staked before and availableBalance is greater than 10k
  if (
    model.forAddr.currentStakedCweb === 0 &&
    availableBalanceForStaking(model) >= 10000
  ) {
    return 10000;
  }
  //if user has not staked before and availableBalance is less than 10k
  else if (
    model.forAddr.currentStakedCweb === 0 &&
    availableBalanceForStaking(model) < 10000
  ) {
    return availableBalanceForStaking(model);
  }
  //if user staked before
  //Due to automatic stake, If there is available balance for the account
  //model.currentStakedCweb would be at least 10k and
  // user would be able to stake as much as s/he can
  else {
    return 0;
  }
};

// This function is to calculate dynamic wallet amount and l2 collateral fields
// function returns array of wallet staking amount, l2 collateral amount, L2 percantage
export const handleDynamicBox = (
  model: StakingData
): [number, number, number | null] => {
  //Function for unstaking
  //If user has not staked before
  //if stake amount is less than 10k,
  //wallet staking amount  will be amount and l2 col will be 0
  if (model.forAddr.currentStakedCweb === 0 && model.amountToStake <= 10000) {
    return [model.amountToStake, 0, null];
  }

  //If user has not staked before
  //if stake amount is greater than 10k,
  // (amount-10k) / 10 = l2
  else if (
    model.forAddr.currentStakedCweb === 0 &&
    model.amountToStake > 10000
  ) {
    const collateral = Number(((model.amountToStake - 10000) / 10).toFixed(3));
    const collateral_percantage = Number(
      ((collateral / model.amountToStake) * 100).toFixed(3)
    );
    return [
      model.amountToStake - collateral,
      collateral,
      collateral_percantage,
    ];
  }

  // if user staked before, currentstaked cweb would be min 10k due to automatic staking
  // so input amount would burn and 10% of the amount would be l2 col
  else if (model.forAddr.currentStakedCweb >= 10000) {
    return [
      Number((model.amountToStake * 0.9).toFixed(3)),
      Number((model.amountToStake * 0.1).toFixed(3)),
      10,
    ];
  }
  return [0, 0, null];
};

export const calculateTime = (deadline: Date) =>
  Date.parse(deadline.toString()) - Date.parse(new Date().toString());

export const calculateTimer = (
  booster: BoosterData
): [
  number | null, //Days
  number | null, //Hours
  number | null, //Minutes
  number | null, //Seconds
  boolean | null // true if active  booster window
  //false if next booster window
  // null if booster window has  ended
] => {
  const currentTime = new Date();
  const startTimeMS = booster.booster.startsAt * 1000;
  const endTimeMs = booster.booster.endsAt * 1000;

  const start = new Date(startTimeMS); //new Date(1656298013000)
  const end = new Date(endTimeMs); //new Date(1657238013000)
  if (currentTime <= start) {
    // next booster window is active
    const startTime = calculateTime(start);
    return [
      Math.floor(startTime / (1000 * 60 * 60 * 24)), // Days
      Math.floor((startTime / (1000 * 60 * 60)) % 24), // Hours
      Math.floor((startTime / (1000 * 60)) % 60), // Minutes
      Math.floor((startTime / 1000) % 60), // Seconds,
      false,
    ];
  } else if (currentTime > end)
    // if booster window has ended
    return [null, null, null, null, null];
  else if (currentTime <= end && currentTime >= start) {
    //Active Boost
    const endTime = calculateTime(end);
    return [
      Math.floor(endTime / (1000 * 60 * 60 * 24)), // Days
      Math.floor((endTime / (1000 * 60 * 60)) % 24), // Hours
      Math.floor((endTime / (1000 * 60)) % 60), // Minutes
      Math.floor((endTime / 1000) % 60), // Seconds,
      true,
    ];
  }
  return [null, null, null, null, null];
};

// Function to calculate % of boost pool
// amount to stake / amount staked during BW
// x -> amount to stake | sum(x) -> staked before + amount to stake | b(x) -> prevously staked amount during booster window
// percentage boost pool => (x + b(x)) / sum(x) * 100
export const percentageBoostPool = (
  model: StakingData,
  totalBoostStakedCweb: number
) =>
  ((model.amountToStake + model.forAddr.currentBoostStakeCweb) /
    (totalBoostStakedCweb + model.amountToStake)) *
  100;

// Function to calculate expected rewards boost
// % of entire BP + amount to stake
// pB() -> percantageBoostWindow | bP -> boost pool
// pB(bP + x)
export const expectedRewardsBoost = (
  model: StakingData,
  rewardPool: number,
  totalBoostStakedCweb: number
) =>
  (percentageBoostPool(model, totalBoostStakedCweb) *
    (rewardPool + 0.05 * model.amountToStake)) /
  100;

// Function to calculate cwebdays until the launcday for expected rewards rate calculation
export const calculateCwebDaysLaunch = (
  model: StakingData,
  daysToLaunch: number
) =>
  model.forAddr.stakedCwebDays +
  daysToLaunch * (model.forAddr.currentStakedCweb + model.amountToStake);

// Function to calculate expected rewards rate
// APR = ((rewardStaking + rewardBooster )* 365) / cweb_days
// reward = reward_staking + reward_booster
// reward_staking = (0.15 * cweb_days) / 365
// rewardBooster = expectedRewardsBoost -> erb
// CASE 1
// if user staked before
// rewardStaking = (cwebDays * 0.15 ) /365
// expectedRewardsRate = ((cwebDays * 0.15 ) /360 + erb ) * 360 / cwebDays
// CASE 2
// if user has not staked before, cweb_days would return 0
// In that case, we assumed the calculation should be for 1 year interval
// rewardStaking = x * 0.15 => since we assume for 1 year period
// expectedRewardsRate = (x * 0.15 + erb ) / x
export const expectedRewardsRate = (
  model: StakingData,
  rewardPool: number,
  totalBoostStakedCweb: number,
  daysToLaunch: number | undefined
) => {
  if (daysToLaunch && calculateCwebDaysLaunch(model, daysToLaunch) > 0) {
    return (
      0.15 *
      (1 +
        expectedRewardsBoost(model, rewardPool, totalBoostStakedCweb) /
          ((0.15 / 365) * calculateCwebDaysLaunch(model, daysToLaunch))) *
      100
    );
  } else {
    return 0;
  }
};
// model.forAddr.stakedCwebDays > 0 ?
//   0.15 + (model.forAddr.totalBoostRewards + expectedRewardsBoost(model, rewardPool, totalBoostStakedCweb)) / (model.forAddr.currentStakedCweb + model.amountToStake)
//   :
//   model.amountToStake > 0
//     ?
//     0.15 + (expectedRewardsBoost(model, rewardPool, totalBoostStakedCweb)) / model.amountToStake
//     : 0;

// APR = (reward * 365) / cweb_days
export const calculateAPR = (model: StakingData): string =>
  model.forAddr.stakedCwebDays > 0
    ? (
        ((calculateRewards(model) * 365) / model.forAddr.stakedCwebDays) *
        100
      ).toFixed(0)
    : "0";
