import { StakeButton } from "../../CustomizedMui";
import "./styles.css";
import { useWallet } from "../../contexts";
import { useState, memo, useEffect } from "react";
import { StakingSignal } from "../LocalStatistics/StakingSignal";
import {
  StakingData,
  signalingMethod,
  SignalingMethod,
  shouldDisplayWarning,
  neededBurnAmount,
  shouldDisplayWarningRecent,
  shouldDisplayWarningAmount,
  shouldDisplayInputError,
  setMinAmountToStake,
  handleDynamicBox,
  percentageBoostPool,
  expectedRewardsBoost,
  expectedRewardsRate,
} from "./model";
import {
  StakeBlueDivider,
  StakeInput,
  StakeInputAdornment,
  StakeFormControl,
  StakeCardDivider,
} from "../../CustomizedMui";
import {
  REACT_APP_COLLATE_OFF,
  REACT_APP_TOTAL_STAKE_AMOUNT_OFF,
  REACT_APP_WALLET_STAKE_OFF,
  REACT_APP_EPOCH_TIME,
} from "../../conf";
import PendingLine from "../PendingLine";
import { useDaysToLaunchLazyQuery } from "../../generated/graphql";
import { Typography } from "@mui/material";
type StakeProps = {
  totalBoostStakedCweb: number;
  rewardPool: number;
  activeBooster: boolean;
  handleCweb: (e: any) => void;
  metamaskAccount: string;
  afterStaking: () => void;
  model: StakingData;
  availableBalanceForStake: number;
  isReady: boolean;
  amountToStakeError: boolean;
};
const warning_text_1 =
  "You are staking a part of your available balance. Note that if you transfer any CWEB out of your account, the staking will be broken. You might want to stake smaller amounts on multiple accounts to reduce this risk.  If you want to stake a larger amount of your account at a later point, you can ignore this warning.";
const warning_text_2 =
  "Recent account movements might not be correctly reflected.";
const warning_text_3 =
  "You are staking a large amount of CWEB.  Consider stakingover multiple addresses to reduce the potential for unwanted unstaking penalties.";
const warning_text_4 =
  "Staking might need a few minutes to be accepted by the network.";

const StakeSection = (props: StakeProps) => {
  const { account, burn, signMessage } = useWallet();
  const {
    totalBoostStakedCweb,
    rewardPool,
    activeBooster,
    handleCweb,
    metamaskAccount,
    afterStaking,
    model,
    availableBalanceForStake,
    isReady,
    amountToStakeError,
  } = props;

  const [walletAmount, setWalletAmount] = useState<number>(0);
  const [L2Amount, setL2Amount] = useState<number>(0);
  const [L2Percentage, setL2Percantage] = useState<null | number>(null);
  const [messageState, setMessage] = useState<string | void>("");
  const [disabledStaking, setDisabledStaking] = useState(false);
  const [stakeWarning, setStakeWarning] = useState(false);
  const [pending, setPending] = useState(false);
  const [DaysToLaunchWindowFn, { data: daysToLaunchResult }] =
    useDaysToLaunchLazyQuery();
  const daysToLaunch = daysToLaunchResult?.daysToLaunch;

  useEffect(() => {
    DaysToLaunchWindowFn();
  }, [activeBooster, DaysToLaunchWindowFn]);
  async function CallStakingSignalContainer() {
    try {
      let address = account;
      let message = messageState;
      const result = StakingSignal({ address, message });
      if (result === "ok" && message !== "") {
        setMessage("");
      }
      if (
        result !== "error without mutation" &&
        messageState !== "" &&
        messageState !== null &&
        (result === "ok" || result === "error") &&
        message !== ""
      )
        setMessage("");
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  CallStakingSignalContainer();
  useEffect(() => {
    const arr = handleDynamicBox(model);
    if (arr) {
      setWalletAmount(arr[0]);
      setL2Amount(arr[1]);
      setL2Percantage(arr[2]);
    }
  }, [model.amountToStake, model]);

  useEffect(() => {
    if (messageState === "error" || messageState === "")
      setDisabledStaking(false);
    else {
      setDisabledStaking(true);
      setStakeWarning(true);
    }
    setPending(false);
  }, [messageState, stakeWarning]);

  async function startstake() {
    if (account) {
      const approach = signalingMethod(model);
      if (approach === SignalingMethod.Burn) {
        const needBurn = neededBurnAmount(model);
        await burn(needBurn, setStakeWarning, setPending, setDisabledStaking);
      } else if (approach === SignalingMethod.Sign) {
        await signMessage(
          "Staking",
          setMessage,
          setStakeWarning,
          setPending,
          setDisabledStaking
        );
      }
    }
    afterStaking();
    setPending(false);
  }

  const checkCweb = () => {
    //if user tries to stake less than minAmount,
    //model.amountToStake will be set to minAmount
    const minAmount = setMinAmountToStake(model);
    if (model.amountToStake < minAmount) handleCweb(minAmount);
    //user wants to stake less than 10k
    //if availableBalanceForStake is greater than 10k
    if (shouldDisplayInputError(model)) handleCweb(10000);
  };
  return (
    <div id="stake_section">
      <p id="available_balance_header">Ready to stake amount</p>
      <p id="available_balance">
        {account ? availableBalanceForStake + " CWEB" : "- -"}
      </p>
      <div className="stake_card">
        <p id="amount_header">Enter amount to stake</p>
        <p id="min_amount_text">
          {availableBalanceForStake > 0
            ? "(Minimum staking amount - " +
              setMinAmountToStake(model).toLocaleString() +
              " CWEB)"
            : "(You have no available balance for staking)"}
        </p>
        {/* Cannot stake if everything is staked  */}
        {/* Cannot decide what to stake if token balance < 10k */}
        <StakeFormControl
          sx={{ width: model.amountToStake.toString().length + 8 + "rem" }}
          variant="standard"
        >
          <StakeInput
            id="input-cweb"
            type="number"
            error={amountToStakeError}
            sx={{
              "&.MuiInput-input": {
                WebkitTextFillColor: amountToStakeError
                  ? "linear-gradient(to right, #5100ff 0%, #ff0063 123%) !important"
                  : undefined,
              },
              WebkitTextFillColor: amountToStakeError
                ? "linear-gradient(to right, #5100ff 0%, #ff0063 123%) !important"
                : undefined,
            }}
            disabled={availableBalanceForStake <= 0 || pending}
            value={model.amountToStake}
            disableUnderline={true}
            onChange={(e) => handleCweb(Number(e.target.value))}
            endAdornment={
              <StakeInputAdornment
                className={
                  availableBalanceForStake === 0 || pending
                    ? "StakeInputDisabled"
                    : undefined
                }
                disablePointerEvents={availableBalanceForStake === 0}
                position="end"
                sx={{
                  color: amountToStakeError ? "#ff0063" : undefined,
                }}
              >
                CWEB{" "}
              </StakeInputAdornment>
            }
            onBlur={() => checkCweb()}
          />
        </StakeFormControl>
        {amountToStakeError && (
          <Typography
            sx={{
              color: "#ff0063",
              fontSize: "12px",
            }}
          >
            You are exceeding the ready to stake amount.
          </Typography>
        )}
        <StakeBlueDivider
          sx={{
            backgroundImage: amountToStakeError
              ? "linear-gradient(to right, #5100ff 0%, #ff0063 123%) !important"
              : undefined,
          }}
        ></StakeBlueDivider>

        <div id="stake_data_section">
          <div className="stake_data_cell">
            <div className="cell_header color_bone ">Total Staking Amount</div>
            {REACT_APP_TOTAL_STAKE_AMOUNT_OFF ? (
              <p className="cell_data color_white">N/A</p>
            ) : (
              <div className="cell_data color_white">
                {model.amountToStake.toLocaleString()} CWEB
              </div>
            )}
          </div>
          <div className="stake_data_cell">
            <div className="cell_header color_bone ">
              Wallet Staking Amount{" "}
              {L2Percentage !== null
                ? "(" + (100 - L2Percentage).toLocaleString() + "%)"
                : null}
            </div>
            {REACT_APP_WALLET_STAKE_OFF ? (
              <p className="cell_data color_white">N/A</p>
            ) : (
              <div className="cell_data color_white">
                {walletAmount.toLocaleString()} CWEB{" "}
              </div>
            )}
          </div>

          <div className="stake_data_cell">
            <div className="cell_header color_bone ">
              L2 Collateral Amount{" "}
              {L2Percentage !== null ? "(" + L2Percentage + "%)" : null}
            </div>
            {REACT_APP_COLLATE_OFF ? (
              <p className="cell_data color_white">N/A</p>
            ) : (
              <div className="cell_data color_white">{L2Amount} CWEB</div>
            )}
          </div>
        </div>
        {activeBooster ? (
          <>
            <StakeCardDivider></StakeCardDivider>
            <div id="stake_data_section">
              <div className="stake_data_cell">
                <div className="cell_header color_bone ">% of boost pool</div>
                <p className="cell_data color_white">
                  {percentageBoostPool(model, totalBoostStakedCweb) === 0
                    ? 0
                    : percentageBoostPool(model, totalBoostStakedCweb).toFixed(
                        3
                      )}
                  %
                </p>
              </div>
              <div className="stake_data_cell">
                <div className="cell_header color_bone ">
                  Expected rewards boost
                </div>
                <p className="cell_data color_white">
                  {expectedRewardsBoost(
                    model,
                    rewardPool,
                    totalBoostStakedCweb
                  ) === 0
                    ? 0
                    : expectedRewardsBoost(
                        model,
                        rewardPool,
                        totalBoostStakedCweb
                      ).toFixed(3)}{" "}
                  CWEB{" "}
                </p>
              </div>
              <div className="stake_data_cell">
                <div className="cell_header color_bone ">
                  Expected boost rate
                </div>
                <p className="cell_data color_white">
                  {expectedRewardsRate(
                    model,
                    rewardPool,
                    totalBoostStakedCweb,
                    daysToLaunch
                  ) === 0
                    ? 0
                    : expectedRewardsRate(
                        model,
                        rewardPool,
                        totalBoostStakedCweb,
                        daysToLaunch
                      ).toFixed(3)}
                  %
                </p>
              </div>
            </div>
          </>
        ) : null}
      </div>
      <div id="important_section">
        <div id="important_cells">
          <div className="cell_header color_white ">IMPORTANT!</div>
          <div className="important_cell_div">
            <span className="cell_important important_cell_margin_right">
              In order to continue to receive rewards, eligibility, and
              privileged access, you{" "}
            </span>
            <span className="cell_header color_white important_cell_margin_right">
              {" "}
              must keep the cweb{" "}
            </span>
            <span className="cell_important important_cell_margin_right">
              {" "}
              in your wallet.{" "}
            </span>
          </div>
        </div>
      </div>
      {(shouldDisplayWarning(model) ||
        shouldDisplayWarningRecent(model) ||
        shouldDisplayWarningAmount(model) ||
        stakeWarning) &&
      model.amountToStake !== 0 ? (
        <div id="warning_section">
          <p className="cell_header color_white ">WARNING!</p>
          <p className="color_white cell_important " id="warning_text">
            {stakeWarning
              ? warning_text_4
              : shouldDisplayWarning(model)
              ? warning_text_1
              : shouldDisplayWarningAmount(model)
              ? warning_text_3
              : warning_text_2}
          </p>
        </div>
      ) : null}
      <div id="stake_button_section">
        <p id="stake_wallet_text">Stake with wallet address </p>
        {pending ? (
          <PendingLine>PENDING...</PendingLine>
        ) : (
          <StakeButton
            type="submit"
            disabled={
              !isReady ||
              disabledStaking ||
              model.tokenBalance === 0 ||
              availableBalanceForStake === 0 ||
              model.amountToStake === 0
            }
            variant="contained"
            onClick={() => {
              startstake();
            }}
          >
            {metamaskAccount}
          </StakeButton>
        )}
      </div>
    </div>
  );
};

export default memo(StakeSection);
